import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, firstValueFrom, forkJoin, map, mergeMap, of } from 'rxjs';
import { BookingUserType } from 'src/app/common/enums/booking-user-type';
import { BookingFactory } from 'src/app/model/booking-factory';
import { User } from 'src/app/model/user';
import { IdInterface } from '../../model/base-model';
import { Booking, BookingInterface, FullResource } from '../../model/booking';
import { FixedParkingInterface, FixedParkingSlot, ParkingSlotSize } from '../../model/parking';
import { ParkingBooking, ParkingBookingInterface } from '../../model/parking-booking';
import { ParkingCession, ParkingCessionInterface } from '../../model/parking-cession';
import { Resource } from '../../model/resource';
import { ParkingEdition } from '../../pages/bookings/parking/edit/edit.component';
import { ParkingCessionEdition } from '../../pages/fixed-resources/parking/cession/cession.component';
import { BookingsApiService } from './bookings-api.service';
import { ApiCustomError } from './common/api-error';
import { ApiPathBuilder, BaseApiService } from './common/base-api.service';
import { UserApiService } from './user-api.service';
import { FreeResources } from './workstation-api.service';

@Injectable({
  providedIn: 'root',
})
export class ParkingApiService extends BaseApiService {
  public static readonly NO_AVAILABLE_RESOURCES = 4030;
  public static readonly INVALID_USER_MAX_PARKING_BOOKING = 4036;

  private fixedParkingSlotCache = new Map<string, Observable<FixedParkingSlot | undefined>>();
  private promiseCessions: Promise<ParkingCession[]> | null = null;

  public constructor(
    http: HttpClient,
    private bookingsApi: BookingsApiService,
    private userApi: UserApiService,
  ) {
    super(http);
  }

  protected override clear(): void {
    this.fixedParkingSlotCache = new Map<string, Observable<FixedParkingSlot | undefined>>();
    this.promiseCessions = null;
  }

  public findFree(parking: ParkingEdition): Promise<readonly Resource[]> {
    if (
      parking.building === null ||
      parking.building.id === null ||
      parking.subcategory === null ||
      parking.subcategory.id === null ||
      parking.floor === null ||
      parking.floor.id === null
    ) {
      throw new Error('Invalid parameters');
    }
    const data: ParkingRequest = {
      buildingId: parking.building.id,
      floorId: parking.floor.id,
      subcategoryId: parking.subcategory.id,
      bookingDays: parking.getDates().map(dates => ({
        startDate: dates.start.getTime(),
        endDate: dates.end.getTime(),
      })),
      features: parking.features.map(feature => feature.id) as string[],
      covered: null,
      parkingSlotSize: null,
    };
    if (parking.vehicle?.vehicleId != null) {
      data.vehicleId = parking.vehicle.vehicleId;
    }
    if (parking.getMaxCostPerHourCalculated() != null) {
      data.maxCostPerHour = parking.getMaxCostPerHourCalculated();
    }
    const path = ApiPathBuilder.v1('parkings', 'bookings', 'manual', 'free');
    return this._post<FreeResources>(path, data).then(
      response => {
        const resources = response.freeResources.map(resource => Resource.create(resource));
        Resource.sort(resources);
        return resources;
      },
      error => {
        if (ApiCustomError.isCode(error, ParkingApiService.NO_AVAILABLE_RESOURCES)) {
          return [];
        } else {
          throw error;
        }
      },
    );
  }

  public findFreeFixedSlots(parking: ParkingEdition): Promise<readonly Resource[]> {
    if (
      parking.building === null ||
      parking.building.id === null ||
      parking.subcategory === null ||
      parking.subcategory.id === null ||
      parking.floor === null ||
      parking.floor.id === null
    ) {
      throw new Error('Invalid parameters');
    }
    const data: ParkingRequest = {
      bookingDays: parking.getDates().map(dates => ({
        startDate: dates.start.getTime(),
        endDate: dates.end.getTime(),
      })),
      features: parking.features.map(feature => feature.id) as string[],
      buildingId: parking.building.id, // TODO: necesarios por error en API, aunque ya se envían en la URL
      floorId: parking.floor.id,
      subcategoryId: parking.subcategory.id,
    };
    if (parking.vehicle?.vehicleId != null) {
      data.vehicleId = parking.vehicle.vehicleId;
    }
    if (parking.getMaxCostPerHourCalculated() != null) {
      data.maxCostPerHour = parking.getMaxCostPerHourCalculated();
    }

    const path = ApiPathBuilder.v1(
      'buildings',
      parking.building,
      'floors',
      parking.floor,
      'cedeFixedParkingSlot',
      'subcategories',
      parking.subcategory,
      'free',
    );
    return this._post<FreeResources>(path, data).then(response => {
      const resources = response.freeResources.map(resource => Resource.create(resource));
      Resource.sort(resources);
      return resources;
    });
  }

  public createManual(parking: ParkingEdition, resource: Resource | FullResource): Promise<readonly Booking[]> {
    if (
      parking.building === null ||
      parking.building.id === null ||
      parking.subcategory === null ||
      parking.subcategory.id === null ||
      parking.floor === null ||
      parking.floor.id === null ||
      resource.id === null
    ) {
      throw new Error('Invalid data');
    }
    const data: ParkingRequestManual = {
      buildingId: parking.building.id,
      floorId: parking.floor.id,
      subcategoryId: parking.subcategory.id,
      bookingDays: parking.getDates().map(dates => ({
        startDate: dates.start.getTime(),
        endDate: dates.end.getTime(),
      })),
      slotSelected: {
        id: resource.id,
      },
    };
    if (parking.vehicle != null && parking.vehicle.vehicleId) {
      data.vehicleId = parking.vehicle.vehicleId;
    }
    if (parking.getMaxCostPerHourCalculated() != null) {
      data.maxCostPerHour = parking.getMaxCostPerHourCalculated();
    }
    const path = ApiPathBuilder.v1('parkings', 'bookings', 'manual');
    return this._post<ParkingResponse>(path, data).then(response => {
      this.promiseCessions = null;
      return this.getCreatedBookings(response);
    });
  }

  public createAutomatic(parking: ParkingEdition): Promise<readonly Booking[]> {
    const data = ParkingApiService.generateAutomaticPayload(parking);
    const path = ApiPathBuilder.v1('parkings', 'bookings', 'manual', 'automatic');
    return this._post<ParkingResponse>(path, data)
      .then(response => this.getCreatedBookings(response))
      .then(response => {
        this.promiseCessions = null;
        return response;
      });
  }

  public createAutomaticFixedSlots(parking: ParkingEdition): Promise<readonly Booking[]> {
    const data = ParkingApiService.generateAutomaticPayload(parking);
    const path = ApiPathBuilder.v1('parkings', 'cededfixedparkingslot', 'bookings', 'manual', 'automatic');
    return this._post<ParkingResponse>(path, data)
      .then(response => this.getCreatedBookings(response))
      .then(response => {
        this.promiseCessions = null;
        return response;
      });
  }

  public update(parking: ParkingEdition, original: ParkingBooking): Promise<ParkingBooking> {
    if (original.id === null) {
      throw new Error('Invalid parameters');
    }
    const dates = parking.getDates()[0];
    const data: { startDate: number; endDate: number } = {
      startDate: dates.start.getTime(),
      endDate: dates.end.getTime(),
    };
    return this._put<ParkingBookingInterface>(ApiPathBuilder.v1('parkings', 'bookings', original), data).then(response =>
      ParkingBooking.create(response),
    );
  }

  private async getCreatedBookings(response: ParkingResponse): Promise<readonly Booking[]> {
    const resources = await firstValueFrom(this.userApi.getResources());
    const bookingsCreated = response.bookings
      .map(booking => BookingFactory.create(booking, BookingUserType.ORGANIZER))
      .map(booking => {
        const subcategory = resources.getSubcategories('PARKING').find(subcatedory => subcatedory.id === booking.resource.subcategory?.id);
        if (subcategory) {
          booking.resource.subcategory = subcategory;
        }
        return booking;
      });

    return firstValueFrom(this.bookingsApi.getList())
      .then(bookings => {
        bookings.merge(bookingsCreated);
        return bookings;
      })
      .then(bookings => {
        const idsCreated = bookingsCreated.map(booking => booking.id);
        const existing = bookings.getOrganizer();
        const created = [];
        for (const id of idsCreated) {
          const foundBooking = existing.find(booking => booking.id === id);
          if (foundBooking) {
            created.push(foundBooking);
          }
        }
        return created;
      });
  }

  public getFixedParkingSlot(user?: User): Observable<FixedParkingSlot | undefined> {
    const id = user?.id || 'me';
    let fixedParkingSlotCache = this.fixedParkingSlotCache.get(id);
    if (fixedParkingSlotCache == null) {
      fixedParkingSlotCache = this.caching(
        this.get<FixedParkingInterface>(ApiPathBuilder.v2('users', id, 'resources', 'parkingslots', 'fixedparkingslot')).pipe(
          mergeMap(fixedParkingSlot => {
            const floorId = fixedParkingSlot.parkingSlot.floor?.id;
            if (floorId == null) {
              throw new Error('Undefined floor for this fixed parking slot');
            }
            return forkJoin([ this.userApi.getResources().pipe(map(resources => resources.findBuildingFromFloor(floorId))), of(fixedParkingSlot) ]);
          }),
          map(([ building, fixedParkingSlot ]) => new FixedParkingSlot(fixedParkingSlot, building ?? undefined)),
          catchError(error => {
            if (error instanceof ApiCustomError && error.statusCode === HttpStatusCode.NotFound) {
              return of(undefined);
            } else {
              throw error;
            }
          }),
        ),
      );
      this.fixedParkingSlotCache.set(id, fixedParkingSlotCache);
    }
    return fixedParkingSlotCache;
  }

  public getCessions(): Promise<ParkingCession[]> {
    if (this.promiseCessions === null) {
      this.promiseCessions = this._get<CessionsResponse>(ApiPathBuilder.v1('parkings', 'cessions')).then(response =>
        response.cessions.map(cession => ParkingCession.create(cession)),
      );
    }
    return this.promiseCessions;
  }

  public createCession(cession: ParkingCessionEdition): Promise<readonly ParkingCession[]> {
    const data: CessionRequest = {
      cessionDays: cession.getDates().map(dates => ({
        startDate: dates.start.getTime(),
        endDate: dates.end.getTime(),
      })),
    };
    return this._post<CessionsResponse>(ApiPathBuilder.v1('parkings', 'cessions'), data).then(response => {
      this.promiseCessions = null;
      return response.cessions.map(newCession => ParkingCession.create(newCession));
    });
  }

  public deleteCession(cession: ParkingCession): Promise<void> {
    return this._delete<void>(ApiPathBuilder.v1('parkings', 'cessions', cession)).then(() => {
      this.promiseCessions = null;
    });
  }

  public deleteRecurrentCessions(cession: ParkingCession): Promise<void> {
    if (cession.recurrentId === null) {
      throw new Error('Non-recurrent cession');
    }
    return this._delete(ApiPathBuilder.v1('parkings', 'cessions', 'recurrent', cession.recurrentId)).then(() => {
      this.promiseCessions = null;
    });
  }

  public deleteAllCessions(): Promise<void> {
    return this._delete(ApiPathBuilder.v1('parkings', 'cessions', 'deleteAll')).then(() => {
      this.promiseCessions = null;
    });
  }

  public checkIn(fixedParkingSlot: FixedParkingSlot): Promise<void> {
    return this._post<void>(ApiPathBuilder.v2('users', 'me', 'fixedresources', 'checkin'), { resourceId: fixedParkingSlot.getResource().id }).then(
      () => {
        fixedParkingSlot.checkInStatus = 'CHECK_IN_DONE';
      },
    );
  }

  public checkOut(fixedWorkstation: FixedParkingSlot): Promise<void> {
    return this._post<void>(ApiPathBuilder.v2('users', 'me', 'fixedresources', 'checkout'), { resourceId: fixedWorkstation.getResource().id }).then(
      () => {
        fixedWorkstation.checkInStatus = 'CHECK_OUT_DONE';
      },
    );
  }

  private static generateAutomaticPayload(parking: ParkingEdition): ParkingRequest {
    if (parking.building?.id == null || parking.subcategory?.id == null || parking.floor?.id == null) {
      throw new Error('Invalid data');
    }
    const data: ParkingRequest = {
      buildingId: parking.building.id,
      floorId: parking.floor.id,
      subcategoryId: parking.subcategory.id,
      bookingDays: parking.getDates().map(dates => ({
        startDate: dates.start.getTime(),
        endDate: dates.end.getTime(),
      })),
      features: parking.features.map(feature => feature.id) as string[],
      covered: null,
      parkingSlotSize: null,
    };
    if (parking.vehicle?.vehicleId) {
      data.vehicleId = parking.vehicle.vehicleId;
    }
    if (parking.getMaxCostPerHourCalculated() != null) {
      data.maxCostPerHour = parking.getMaxCostPerHourCalculated();
    }
    return data;
  }
}

interface ParkingRequest {
  buildingId: string;
  floorId: string;
  subcategoryId: string;
  bookingDays: readonly {
    startDate: number;
    endDate: number;
  }[];
  features: readonly string[];
  covered?: boolean | null;
  parkingSlotSize?: ParkingSlotSize | null;
  vehicleId?: string;
  maxCostPerHour?: number;
}

interface ParkingRequestManual extends Omit<ParkingRequest, 'covered' | 'parkingSlotSize' | 'features'> {
  slotSelected: IdInterface;
}

interface ParkingResponse {
  bookings: readonly BookingInterface[];
}

interface CessionsResponse {
  cessions: readonly ParkingCessionInterface[];
}

interface CessionRequest {
  readonly cessionDays: readonly {
    startDate: number;
    endDate: number;
  }[];
}
